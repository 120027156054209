// Supermove
import {useRef} from '@supermove/hooks';

export type ScrollViewType = {
  ref: React.RefObject<HTMLDivElement>;
  handleScrollTo: ({x, y, animated}: {x?: number; y?: number; animated?: boolean}) => void;
  handleScrollToTop: ({animated}: {animated?: boolean}) => void;
  handleScrollToEnd: ({animated}: {animated?: boolean}) => void;
};

const useScrollView = () => {
  // TODO(jay): Figure out why ref types aren't working properly for ScrollView
  const ref = useRef<any>();
  const handleScrollTo = ({x, y, animated}: {x?: number; y?: number; animated?: boolean}) =>
    ref.current.scrollTo({x, y, animated});
  const handleScrollToTop = ({animated}: {animated?: boolean}) =>
    ref.current.scrollTo({y: 0, animated});
  const handleScrollToEnd = ({animated}: {animated?: boolean}) =>
    ref.current.scrollToEnd({animated});

  return {
    ref,
    handleScrollTo,
    handleScrollToTop,
    handleScrollToEnd,
  };
};

export default useScrollView;
